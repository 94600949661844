// MBQ TODO AI POC
import React, { Component, Fragment } from 'react'
import {
  Card, CardBody, Container, Row, Col, Progress, Alert,
  Form, FormGroup, Input, Label
} from 'reactstrap'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'
import Modal from '../../Modal/Modal'
import PropTypes from 'prop-types'
import axios from 'axios'
import axiosConfig from '../../../config/axios'
import _ from 'lodash'
import * as AI_UTILS from './ai-link-utils'
import aiLinkImg from './resources/bot.png'
//import aiLinkImg from './resources/ai_scroll.png'
//import aiLinkImg from './resources/pergamino.jpg'
//import aiLinkImg from './resources/robot.png'

//const axiosClient = axios.create(axiosConfig)
const axiosClient = axios.create({ ...axiosConfig, timeout: AI_UTILS.TIMEOUT })
//const axiosClient = axios.create(AI_UTILS.axiosConfig)

class AILink extends Component {
  constructor() {
    super()
    this.state = {
      unit: 0,
      selectedUnit: null,
      exercise: 0,
      selectedExercise: null,
      question: '',
      openModal: false,
      errors: {
        unit: true,
        exercise: true,
        question: false
      },
      failure: null,
      info: null,
      result: null,
      loading: false
    }
  }

  resetData = () => (
    this.setState({
      unit: 0,
      selectedUnit: null,
      exercise: 0,
      selectedExercise: null,
      question: '',
      openModal: false,
      errors: {
        unit: true,
        exercise: true,
        question: false
      },
      failure: null,
      info: null,
      result: null,
      loading: false
    })
  )

  setError = error => (
    this.setState({
      unit: 0,
      selectedUnit: null,
      exercise: 0,
      selectedExercise: null,
      question: '',
      errors: {
        unit: true,
        exercise: true,
        question: false
      },
      failure: error || 'Ha ocurrido un error inesperado. Por favor, inténtelo de nuevo.',
      info: null,
      result: null,
      loading: false
    })
  )

  handleInputChange = e => {
    e.preventDefault()
    const name = e.target.name
    const value = name === 'question' ? e.target.value : Number.parseInt(e.target.value)
    switch (name) {
      case 'unit':
        this.setState({
          unit: value,
          selectedUnit: value !== 0 ? AI_UTILS.getSelectedUnit(value) : null,
          errors: { ...this.state.errors, unit: value <= 0 },
          failure: null,
          info: null,
          result: null
        })
        break
      case 'exercise':
        const updatedState = {
          ...this.state,
          exercise: value,
          selectedExercise: value !== 0 ? AI_UTILS.getSelectedExercise(value) : null,
          errors: {
            ...this.state.errors,
            exercise: value <= 0
          },
          failure: null,
          info: null,
          result: null
        }
        if (updatedState.selectedExercise && AI_UTILS.isUserQuestionExercise(updatedState.selectedExercise)) {
          updatedState.errors.question = !this.state.question.length > 0
        } else {
          updatedState.errors.question = false
          updatedState.question = ''
        }
        this.setState({ ...updatedState })
        break
      case 'question':
        this.setState({
          question: value,
          errors: { ...this.state.errors, question: !value.trim().length > 0 },
          failure: null,
          info: null,
          result: null
        })
        break
      default:
        break
    }
  }

  validateForm = () => (
    !_.compact(_.values(this.state.errors)).length
  )

  handleEnter = e => {
    if (e.key === 'Enter') this.handleSubmit(e)
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.validateForm()) {
      this.setState({ loading: true }, () => {
        const url = `https://${this.state.selectedExercise.config.host}/`
        const data = AI_UTILS.getGeneratorRequestData(this.state.selectedExercise, this.state.question.trim())
        axiosClient.post('/ai-generator/exercise', { url, data })
          .then(response => {
            if (response.data) {
              this.setState({
                result: response.data.questions || response.data.exercise,
                info: response.data.info || null,
                loading: false
              })
            } else {
              console.error('AI-GENERATOR : Error no response')
              this.setError()
            }
          }).catch(error => {
            console.error('AI-GENERATOR : ', error.toString())
            if (error.response && error.response.data) {
              this.setError(error.response.data.message)
            } else {
              this.setError()
            }
          })
      })
    }
  }

  render() {
    return (
      <Fragment>

        <div id='bt-ai' className='plus__Col position-relative mx-4'>
          <Card className='mb-4'>
            <div className='products__card position-relative w-100 h-100'>
              <img
                className='products__card__inside__thumbnail w-100'
                alt={'Generar ejercicio IA'}
                title={'Generar ejercicio IA'}
                src={aiLinkImg}
                onClick={() => this.setState({ openModal: true })}
              />
            </div>
            <CardBody className='p-0'>
              <div className='products__card__inside__legend w-100 position-realtive'>
                <div className='py-1 px-2'>Generar ejercicio IA</div>
              </div>
            </CardBody>
          </Card>
        </div>

        <Modal
          isOpen={this.state.openModal}
          toggle={this.resetData}
          size='lg'
          className='position-relative'
          header='Generar ejercicio IA'
          subtitle={this.props.title.name}
        >
          <Container fluid={true} id='modal-ai'>
            {(this.state.loading || this.state.failure !== null || this.state.info !== null) && (
              <Row>
                <Col className='mt-4'>
                  {this.state.loading && (
                    <Progress animated color='primary' value='100'>Cargando...</Progress>
                  )}
                  {
                    this.state.failure !== null && (
                      <Alert color='danger'>{this.state.failure}</Alert>
                    )
                  }
                  {
                    this.state.info !== null && (
                      <Alert color='primary'>{this.state.info}</Alert>
                    )
                  }
                </Col>
              </Row>
            )}
            <Row>
              <Col className='mt-4'>
                <Form noValidate>
                  <FormGroup>
                    <Label for='unit'>Unidad</Label>
                    <Input
                      type='select'
                      id='unit'
                      name='unit'
                      autoComplete='off'
                      value={this.state.unit}
                      onChange={this.handleInputChange}
                      tabIndex='1'
                    >
                      <option key={0} value={0}>Seleccione la unidad</option>
                      {AI_UTILS.MOCK_TITLE_UNITS.map((unit, index) => (
                        <option
                          key={index}
                          value={Number.parseInt(unit.id)}
                        >
                          {unit.desc}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for='exercise'>Tipo de ejercicio</Label>
                    <Input
                      type='select'
                      id='exercise'
                      name='exercise'
                      autoComplete='off'
                      value={this.state.exercise}
                      onChange={this.handleInputChange}
                      tabIndex='2'
                    >
                      <option key={0} value={0}>Seleccione el tipo de ejercicio</option>
                      {AI_UTILS.MOCK_AI_EXERCISE_TYPES.map((type, index) => (
                        <option
                          key={index}
                          value={Number.parseInt(type.id)}
                        >
                          {type.desc}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  {
                    this.state.selectedExercise && AI_UTILS.isUserQuestionExercise(this.state.selectedExercise) && (
                      <FormGroup>
                        <Label for='question'>Pregunta</Label>
                        <Input
                          type='text'
                          id='question'
                          name='question'
                          autoComplete='off'
                          value={this.state.question}
                          onChange={this.handleInputChange}
                          onKeyPress={this.handleEnter}
                          placeholder={'Indique su pregunta'}
                          tabIndex='3'
                        />
                      </FormGroup>
                    )
                  }
                  <FormGroup>
                    <Row className='mt-5 mb-5'>
                      <Col md='5' lg={{ size: 3, offset: 2 }} className='my-2'>
                        <ButtonPremium onClick={this.resetData} tabIndex='5'>Volver</ButtonPremium>
                      </Col>
                      <Col md='7' lg={{ size: 6, offset: 1 }} className='my-2'>
                        <Row className='h-100'>
                          <Col className='h-100'>
                            <ButtonPremium
                              disabled={!this.validateForm() || this.state.loading || this.state.result !== null}
                              onClick={this.handleSubmit}
                              tabIndex='4'
                              primary
                            >
                              Generar ejercicio
                            </ButtonPremium>
                          </Col>
                          <Col className='align-self-center'>
                            {
                              this.state.result !== null ?
                                (
                                  <a
                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                      JSON.stringify(this.state.result)
                                    )}`}
                                    download={`${this.props.title.name}_unit.${this.state.selectedUnit.name}_exercise.${this.state.selectedExercise.name}_${Date.now()}.json`}
                                    title='Descargar ejercicio'
                                  >
                                    <span className='glyphicon glyphicon-download active' tabIndex={0}></span>
                                  </a>
                                )
                                :
                                (
                                  <span className='glyphicon glyphicon-download inactive'></span>
                                )
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Fragment>
    )
  }
}

AILink.propTypes = {
  title: PropTypes.object.isRequired
}

export default AILink