import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import Modal from '../../Modal/Modal'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'
import CMSContent from '../../CMSContent/CMSContent'

import { LocalPublishingManagedSurveyButton } from 'components/Buttons/LocalPublishingManagedSurveyButton'

import { withContent } from 'utils/hocs'

const LocalPublishingManagedSurveyModal = withContent(({
  content,
  isLastTime = false,
  locale,
  onDeny,
  onResponse
}) => {

  return (
    <Modal isOpen={true} size="lg" centered={true} scrollable={false} className='ManagedSurvey'>
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Col className="localPublishingModalContent">
            <CMSContent pageid={ !isLastTime ? 'PREMIUM_LOCALP_MANAGED_SURVEY' : 'PREMIUM_LOCALP_MANAGED_SURVEY2'} language={locale} />
          </Col>
        </Row>
        <Row className="pt-3 justify-content-center">
          <Col xs="12" md="6" className="pb-3 pb-md-0 align-self-start">
            <ButtonPremium
              onClick={event => {
                event.preventDefault()
                onDeny()
              }}
              tabIndex="2"
            >
              { !isLastTime ? _.get(content, 'polls.button_show_later') : _.get(content, 'polls.button_not_show_again') }
            </ButtonPremium>
          </Col>
          <Col xs="12" md="6" className="d-flex justify-content-end">
            <LocalPublishingManagedSurveyButton
              onClick={event => {
                event.preventDefault()
                onResponse()
              }}
              tabIndex="1"
              primary
            >
              { _.get(content, 'polls.button_local_publishing_modal_open_survey') }
            </LocalPublishingManagedSurveyButton>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
})

LocalPublishingManagedSurveyModal.propTypes = {
  isLastTime: PropTypes.bool,
  onDeny: PropTypes.func.isRequired,
  onResponse: PropTypes.func.isRequired,
}

export default LocalPublishingManagedSurveyModal
