// MBQ TODO AI POC
//export const TIMEOUT = 60000
export const TIMEOUT = 120000

export const axiosConfig = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: TIMEOUT,
  crossdomain: true,
  responseType: 'json',
  responseEncoding: 'utf8'
}

export const USER_QUESTION_CONFIG = {
  host: 'zrkspzbokp4trf2gfhghdpus6q0wsclq.lambda-url.us-east-1.on.aws',
  data: {
    question_type: 'USER_QUESTION_WITH_4',
    bucket_name: 'jlo-generative-ai-content',
    bucket_key: 'U3_GH_3ESO_GENIOXLOMLOE_AND_WITH_CONTEXT.txt',
    unit_id: 10
  }
}

export const MULTIPLE_QUESTION_CONFIG = {
  //host: 'l56yojbblrnqgiosh4ahusvbfi0dnenj.lambda-url.us-east-1.on.aws',
  host: 'x5zl5wiptfcvluflu3hj2v7fiu0dcyla.lambda-url.us-east-1.on.aws',
  data: {
    question_type: 'MULTIPLE_QUESTION_WITH_4',
    bucket_name: 'jlo-generative-ai-content',
    bucket_key: 'U3_GH_3ESO_GENIOXLOMLOE_AND_SUMMARY.txt',
    unit_id: 10
  }
}

export const MOCK_TITLE_UNITS = [
  { id: 1, name: 'unit1', desc: 'Unidad 1: Desafíos demográficos en la actualidad' },
  { id: 2, name: 'unit2', desc: 'Unidad 2: Economía y actividades económicas' },
  { id: 3, name: 'unit3', desc: 'Unidad 3: Agricultura, ganadería y pesca' },
  { id: 4, name: 'unit4', desc: 'Unidad 4: Minería, industria y construcción' },
  { id: 5, name: 'unit5', desc: 'Unidad 5: Actividades terciarias' },
  { id: 6, name: 'unit6', desc: 'Unidad 6: Relaciones entre países' },
  { id: 7, name: 'unit7', desc: 'Unidad 7: La Unión Europea y España' },
  { id: 8, name: 'unit8', desc: 'Unidad 8: Un mundo desigual' },
  { id: 9, name: 'unit9', desc: 'Unidad 9: Sostenibilidad y medio ambiente' },
  { id: 10, name: 'unit10', desc: 'Unidad 10: Retos y globalización' }
]

export const MOCK_AI_EXERCISE_TYPES = [
  { id: 1, name: 'userquestion', desc: 'Pregunta libre', config: USER_QUESTION_CONFIG },
  { id: 2, name: 'multiplequestion4', desc: 'Cuestionario 4 opciones', config: MULTIPLE_QUESTION_CONFIG },
  { id: 3, name: 'multiplequestion6', desc: 'Cuestionario 6 opciones', config: MULTIPLE_QUESTION_CONFIG }
]

export const getSelectedUnit = id => (
  MOCK_TITLE_UNITS.find(unit => unit.id === id)
)

export const getSelectedExercise = id => (
  MOCK_AI_EXERCISE_TYPES.find(type => type.id === id)
)

export const isUserQuestionExercise = exercise => (
  exercise.config.data.question_type === USER_QUESTION_CONFIG.data.question_type
)

export const getGeneratorRequestData = (exercise, question) => {
  const requestData = { ...exercise.config.data }
  if (isUserQuestionExercise(exercise)) {
    requestData.question = question
  }
  return requestData
}
