import { useEffect, useState } from 'react'

export const useSchoolSchedule = () => {
  const [ isSchoolSchedule, setSchoolSchedule ] = useState()

  useEffect(() => {
    const checkInSchedule = () => {
      const now = new Date()
      const currentDay = now.getDay()

      if([0, 6].includes(currentDay)) {
        setSchoolSchedule(false)
      } else {
        const currentHour = now.getHours()
        const currentMinute = now.getMinutes()

        const isAfterStart = currentHour >= 9
        const isBeforeEnd = currentHour < 15 || (currentHour === 15 && currentMinute < 30)

        setSchoolSchedule(isAfterStart && isBeforeEnd)
      }
    }

    checkInSchedule()
    const intervalId = setInterval(checkInSchedule, 60000)

    return () => clearInterval(intervalId)
  }, [])

  return isSchoolSchedule
}