import { useEffect, useState } from 'react'

export const useScrollTo = (x, y) => {
  useEffect(() => {
    window.scrollTo(x, y)
  })
}

export const useScrollToTop = () => useScrollTo(0, 0)

export const useScroll = () => {
  const [ x, setX ] = useState()
  const [ y, setY ] = useState()

  useEffect(() => {
    const setScroll = () => {
      setX(window.scrollX)
      setY(window.scrollY)
    }

    window.addEventListener('scroll', setScroll)

    return () => {
      window.removeEventListener('scroll', setScroll)
    }

  }, [])
  
  return { x, y}
}
