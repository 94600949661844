import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import axios from 'axios'

import { useSchoolSchedule } from 'hooks'
import { usePollConfig } from 'contexts/Polls/PollContext'
import LocalPublishingManagedSurveyModal from './LocalPublishingManagedSurveyModal'
import { LocalPublishingManagedSurveyButton } from 'components/Buttons/LocalPublishingManagedSurveyButton'

import { withContent } from 'utils/hocs'

import axiosConfig from '../../../config/axios'

const SURVEY_ACTIONS = {
  RESPONSE: 'RESPONDER',
  DENY: 'DENEGAR',
  DELAY: 'RETRASAR'
}

const send = (action, refresh) => (
  axios.create(axiosConfig).post('/user/managed-survey/local', { action })
    .then(response => {
      console.log(`OXED MANAGED SURVEY : ${_.get(response, 'data', 'OK')}`)
    })
    .catch(error => {
      console.error('OXED MANAGED SURVEY : ', error.toString())
    }).finally(() => (
      refresh()
    ))
)

export const LocalPublishingManagedSurvey = withContent(({content}) => {
  const isSchoolSchedule = useSchoolSchedule()
  const {
    localPublishingManagedSurveyConfig,
    refreshLocalPublishingManagedSurveyConfig
  } = usePollConfig()
  const [ showModal, setShowModal ] = useState(false)

  useEffect(() => {
    const {
      url,
      delayNumber: delay,
      maxDelayNumber: maxDelay,
    } = localPublishingManagedSurveyConfig || {}

    if(url && (url !== '') && (delay <= maxDelay)) {
      setShowModal(true)
    }


  }, [localPublishingManagedSurveyConfig])

  const {
    url,
    delayNumber: delay,
    maxDelayNumber: maxDelay,
    showButton
  } = localPublishingManagedSurveyConfig || {}


  if(!localPublishingManagedSurveyConfig || 
    (url === '') ||
    !showButton
  ) return null

  const lpModal = !isSchoolSchedule && showModal && !(delay > maxDelay)

  const modalDeny = () => {
    const action = delay < maxDelay ? SURVEY_ACTIONS.DELAY : SURVEY_ACTIONS.DENY
    send(action, refreshLocalPublishingManagedSurveyConfig)
    setShowModal(false)
  }

  const buttonOnClick = () => {
    send(SURVEY_ACTIONS.RESPONSE, refreshLocalPublishingManagedSurveyConfig)
    window.open(url)
  }

  const modalResponse = () => {
    buttonOnClick()
    setShowModal(false)
  }

  return (
    <>
      { lpModal && (
        <LocalPublishingManagedSurveyModal
          isLastTime={delay === maxDelay}
          onDeny={modalDeny}
          onResponse={modalResponse}
        />
      )}
      { showButton && (
        <div className="d-flex my-auto align-content-center mx-2">
          <LocalPublishingManagedSurveyButton
            onClick={buttonOnClick}
          >
            { _.get(content, 'polls.button_local_publishing_title') }
          </LocalPublishingManagedSurveyButton>
        </div>
      )}
    </>
  )
})
