import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import './styles.scss'

export const LocalPublishingManagedSurveyButton = ({
  className,
  children,
  onClick
}) => (
  <Button
    color="LocalPublishingManagedSurvey"
    className={className}
    onClick={onClick}
  >
    { children }
  </Button>
)

LocalPublishingManagedSurveyButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
}
