import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'

import { withContent, withUserInfo } from '../../utils/hocs'
import { useFeature } from '../Feature/FeatureContext'
import axiosConfig from "../../config/axios"
import userRoles from '../../globals/userRoles'

export const PollConfigContext = createContext()
export const usePollConfig = () => useContext(PollConfigContext)

const axiosInstance = axios.create(axiosConfig)

export const PollConfigProvider = withContent(withUserInfo(({
  userInfo,
  locale,
  children
}) => {
  const {
    polls: pollsFeature,
    managedSurvey: managedSurveyFeature,
    localPublishingSurvey: localPublishingManagedSurveyFeature
  } = useFeature()
  const [ pollConfig, setPollConfig ] = useState()
  const [ managedSurveyConfig, setManagedSurveyConfig ] = useState()
  const [ localPublishingManagedSurveyConfig, setLocalPublishingManagedSurveyConfig ] = useState({})
  const [ refreshLocalPublishingManagedSurvey, setRefreshLocalPublishingManagedSurvey ] = useState(true)

  const { idSim, role } = userInfo || {}

  const managedSurveyEnabled = (
    managedSurveyFeature &&
    role && role === userRoles.TEACHER
  )

  useEffect(() => {
    if(pollsFeature) {
      axiosInstance.get("user/polls")
        .then((response) => {
          if(response.data && Object.keys(response.data).length) {
            setPollConfig(response.data)
          }
        })
        .catch(() => setPollConfig(null))
    }
  }, [idSim, pollsFeature])

  useEffect(() => {
    if(managedSurveyEnabled) {
      axiosInstance.get("user/managed-survey/satisfaction")
        .then((response) => {
          if(response.data && Object.keys(response.data).length) {
            setManagedSurveyConfig(response.data)
          }
        })
        .catch(error => {
          console.error("Error getting managed survey configuration: ", error.toString())
          setManagedSurveyConfig(null)
        })
    }
  }, [idSim, managedSurveyEnabled])
  
  useEffect(() => {
    if(localPublishingManagedSurveyFeature && refreshLocalPublishingManagedSurvey) {
      axiosInstance.get("user/managed-survey/local")
        .then((response) => {
          if(response.data && Object.keys(response.data).length) {
            const { url } = response.data
            if(url && url!=='') {
              setLocalPublishingManagedSurveyConfig({
                ...response.data,
                url: `${url}&lang=${locale==='es'?"2":"1"}`
              })
            } else {
              setLocalPublishingManagedSurveyConfig(response.data)
            }
          }
        })
        .catch(error => {
          console.error("Error getting managed survey configuration: ", error.toString())
          setLocalPublishingManagedSurveyConfig(null)
        })
      setRefreshLocalPublishingManagedSurvey(false)
    }
  }, [idSim, locale, localPublishingManagedSurveyFeature, refreshLocalPublishingManagedSurvey])

  const refreshLocalPublishingManagedSurveyConfig = () => setRefreshLocalPublishingManagedSurvey(true)

  return (
    <PollConfigContext.Provider
      value={{
        pollConfig,
        managedSurveyConfig,
        localPublishingManagedSurveyConfig,
        refreshLocalPublishingManagedSurveyConfig
      }}
    >
      {children}
    </PollConfigContext.Provider>
  )
}))
