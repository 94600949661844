import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Modal from '../../Modal/Modal'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'
import CMSContent from '../../CMSContent/CMSContent'
import _ from 'lodash'
import axios from 'axios'
import axiosConfig from '../../../config/axios'

const SURVEY_ACTIONS = {
  RESPONSE: 'RESPONDER',
  DENY: 'DENEGAR',
  DELAY: 'RETRASAR'
}

const send = (action, refresh) => (
  axios.create(axiosConfig).post('/user/managed-survey/satisfaction', { action })
    .then(response => {
      console.log(`MANAGED SURVEY : ${_.get(response, 'data', 'OK')}`)
    })
    .catch(error => {
      console.error('MANAGED SURVEY : ', error.toString())
    }).finally(() => (
      refresh()
    ))
)

const ManagedSurveyModal = ({ delayNumber, maxDelayNumber, url, locale, refreshAuth, pollText }) => (
  <Modal isOpen={true} size="lg" centered={true} scrollable={false} className='ManagedSurvey'>
    <Container fluid={true}>
      <Row className="justify-content-center">
        <Col>
          <CMSContent pageid={delayNumber < maxDelayNumber ? 'PREMIUM_MANAGED_SURVEY' : 'PREMIUM_MANAGED_SURVEY2'} cache={true} language={locale} />
        </Col>
      </Row>
      <Row className="pt-3 justify-content-center">
        <Col xs="12" md="6" className="pb-3 pb-md-0 align-self-start">
          <ButtonPremium
            onClick={event => {
              event.preventDefault()
              const action = delayNumber < maxDelayNumber ? SURVEY_ACTIONS.DELAY : SURVEY_ACTIONS.DENY
              send(action, refreshAuth)
            }}
            tabIndex="2"
          >
            {delayNumber < maxDelayNumber ? pollText.button_show_later : pollText.button_not_show_again}
          </ButtonPremium>
        </Col>
        <Col xs="12" md="6" className="align-self-end">
          <ButtonPremium
            onClick={event => {
              event.preventDefault()
              send(SURVEY_ACTIONS.RESPONSE, refreshAuth)
              window.open(url)
            }}
            tabIndex="1"
            primary
          >
            {pollText.button_open_survey}
          </ButtonPremium>
        </Col>
      </Row>
    </Container>
  </Modal>
)

export default ManagedSurveyModal