const appProtocol = window.location.protocol
const appHost = window.location.host
const domain = window.location.hostname
const PRODUCTION_DOMAIN = 'oxfordpremium.oupe.es'
const PRODUCTION_DOMAIN_PLUS = 'oxfordplus.oupe.es'
const PRODUCTION_DOMAIN_OUP = 'www.oup.es'
const PLUS = 'oxfordplus'

const LDAP = { DEV: 3, PREPRO: 1, PROD: 2}
const GOOGLE = { DEV: 6, PREPRO: 4, PROD: 5}
const MICROSOFT = { DEV: 9, PREPRO: 7, PROD: 8}

const app_baseUrl = `${appProtocol}//${appHost}`

const app_basepath = '/app'

export const isProductionEnv = () => domain === PRODUCTION_DOMAIN || domain === PRODUCTION_DOMAIN_PLUS || domain === PRODUCTION_DOMAIN_OUP

export const isPreProEnv = () => /prepro2?\./.test(domain)

export const isDevelopmentEnv = () => /dev\./.test(domain)

export const isLocalEnv = () => /local\./.test(domain)

export const isPlusPortal = () => new RegExp(PLUS).test(domain)

let api_baseUrl = process.env.REACT_APP_API

if(!api_baseUrl) {
  if(isProductionEnv()) api_baseUrl = 'https://api.oxfordpremium.oupe.es'
  else if(isPreProEnv()) api_baseUrl = 'https://api.oxfordpremium.prepro2.oupe.es'
  else if(isDevelopmentEnv()) api_baseUrl = 'https://api.oxfordpremium.dev.oupe.es'
  else api_baseUrl = 'https://api.oxfordpremium.local.oupe.es'
}

export const ldapPlatform = () => (isProductionEnv() ? LDAP.PROD : (isPreProEnv() ? LDAP.PREPRO : LDAP.DEV))
export const googlePlatform = () => (isProductionEnv() ? GOOGLE.PROD : (isPreProEnv() ? GOOGLE.PREPRO : GOOGLE.DEV))
export const microsoftPlatform = () => (isProductionEnv() ? MICROSOFT.PROD : (isPreProEnv() ? MICROSOFT.PREPRO : MICROSOFT.DEV))

export const isStudentArea = path => /^\/student\/(\w+)/g.test(path)
export const isClassroomError = path => /^\/classroom\-(\w)/g.test(path)

export const isProductViewer = path => /^\/product-viewer(\/|\?|$)(\w*)/g.test(path)

const currentUrl = window.location.href;

const staticUrl = () => {

  if (isLocalEnv()) {
    return ''
  }

  return `${appProtocol}//static.${isDevelopmentEnv() ? 'dev.' : ''}${isPreProEnv() ? 'prepro2.' : ''}oupe.es`

}

const recaptchaKey = () => {
  if(isProductionEnv()) return "6LdDhLoUAAAAANPZ5KtQeEZ1Ypnqt_SxLdAi_U1M"
  if(isPreProEnv()) return "6Lft1bIUAAAAAPWi_-DBJbV1bm35QQJWaX3MxBLv"
  if(isDevelopmentEnv()) return "6Lft1bIUAAAAAPWi_-DBJbV1bm35QQJWaX3MxBLv"
  if(isLocalEnv()) return "6Le4xbAUAAAAAKEwYYUdBDIKPhdT95fjEb04kKm4"
  return "6LdDhLoUAAAAANPZ5KtQeEZ1Ypnqt_SxLdAi_U1M"
}

const students_ping_interval = () => {
  if(isProductionEnv()) return 120000
  if(isPreProEnv()) return 120000
  if(isDevelopmentEnv()) return 120000
  if(isLocalEnv()) return 120000
  return 120000
}

const students_max_devices = () => {
  if(isProductionEnv()) return 3
  if(isPreProEnv()) return 3
  if(isDevelopmentEnv()) return 3
  if(isLocalEnv()) return 3
  return 3
}

const students_add_days = () => {
  if(isProductionEnv()) return 7
  if(isPreProEnv()) return 7
  if(isDevelopmentEnv()) return 7
  if(isLocalEnv()) return 7
  return 7
}

const students_substract_days = () => {
  if(isProductionEnv()) return -1
  if(isPreProEnv()) return -1
  if(isDevelopmentEnv()) return -1
  if(isLocalEnv()) return -1
  return -1
}

const wootric_survey_immediately = () => {
  if(isProductionEnv()) return "false"
  if(isPreProEnv()) return "false"
  if(isDevelopmentEnv()) return "true"
  if(isLocalEnv()) return "true"
  return "false"
}

const wootric_no_surveyed_cookie = () => {
  if(isProductionEnv()) return "false"
  if(isPreProEnv()) return "false"
  if(isDevelopmentEnv()) return "true"
  if(isLocalEnv()) return "true"
  return "false"
}

const localPublishingProjects = () => {
  if(isProductionEnv()) return [
    '41ce7633-0f11-4be8-9253-2c7b4631bfe8',
    '7878eb8c-8990-42ba-90d4-49761ed58a82',
    'fb932e3f-b579-4561-8b84-37632e180599'
  ]
  if(isPreProEnv()) return [
    '605e6c34-c165-49a5-88a5-649b1e834f29',
    'd20f7fe5-36c6-434c-949b-3880562e176b',
    '9b79eb5a-9933-4fb8-8ca8-4fbfeae93260'
  ]
  if(isDevelopmentEnv()) return [
    '28b58cc4-e161-4447-8378-e734f3a84faa',
    '1683b891-d4d2-4390-8b7e-6e8d3e131bf4',
    '77839fbe-3cd6-4f00-8f69-03a524c18996'
  ]
  if(isLocalEnv()) return [
    '28b58cc4-e161-4447-8378-e734f3a84faa',
    '1683b891-d4d2-4390-8b7e-6e8d3e131bf4',
    '77839fbe-3cd6-4f00-8f69-03a524c18996'
  ]
  return []
}

export default Object.assign(process.env, {
  DOMAIN: domain,
  APP_BASEURL: app_baseUrl + app_basepath,
  APP_BASEPATH: app_basepath,
  API_BASEURL: api_baseUrl,
  WORDPRESS_API_BASEURL: 'https://help.oupe.es/wp-json/wp/v2/',
  AUTH_URL: `${api_baseUrl}/user?returnTo=${encodeURIComponent(currentUrl)}`,
  //LOGIN_URL: `${api_baseUrl}/login?returnTo=${encodeURIComponent(currentUrl)}`,
  LOGIN_URL: `${app_basepath}/signin`,
  LOGOUT_URL: `${api_baseUrl}/signout`,
  STATIC_URL:  staticUrl(),
  CONTENT_URL: `${api_baseUrl}/content`,
  CONTACT_URL: `${app_basepath}/contact`,
  PASSWORD_URL: `${app_basepath}/forgot-password`,
  COUNTRY_ESP_ID: 1131,
  RECAPTCHA_CLIENT_ID: recaptchaKey(),
  PLATFORM_LDAP_ID: ldapPlatform(),
  PLATFORM_GOOGLE_ID: googlePlatform(),
  PLATFORM_MICROSOFT_ID: microsoftPlatform(),
  RESOURCE_NEW_THRESHOLD: 15,
  STUDENTS_PING_INTERVAL: students_ping_interval(),
  STUDENTS_MAX_DEVICES: students_max_devices(),
  STUDENTS_ADD_DAYS: students_add_days(),
  STUDENTS_SUBSTRACT_DAYS: students_substract_days(),
  WOOTRIC_SURVEY_IMMEDIATELY: wootric_survey_immediately(),
  WOOTRIC_NO_SURVEYED_COOKIE: wootric_no_surveyed_cookie(),
  WOOTRIC_MODAL_THEME: 'light',
  WOOTRIC_MODAL_FOOTPRINT: 'spacious',
  WOOTRIC_MODAL_POSITION: 'bottom',
  WOOTRIC_ARIA: true,
  WOOTRIC_SCALE_COLOR: 'gradient',
  WOOTRIC_SURVEY_TYPE_SCALE: 1,
  LOCAL_PUBLISHING_PROJECTS: localPublishingProjects()
})
